<template>
  <div class="nav-list" :class="{ scale: navScale }">
    <ul class="nav-list-wrapper">
      <li
        class="nav-list-item"
        :class="{ active: activeCategory == item.value }"
        @click="goCategory(item)"
        v-for="(item, index) in categoryList"
        :key="index"
      >
        <img
          class="item-icon"
          :src="$store.state.assetsUrl + '/assets/imgs/icons/' + item.icon"
        />
        <p class="item-label">
          <span class="item-label__cn">{{ item.label }}</span>
          <span class="item-label__en">{{ item.color }}</span>
        </p>
      </li>
    </ul>
    <h2 class="category">
      <span class="category-title">{{ categoryName }}</span>
      <span class="category-post" @click="goPost">资源发布</span>
    </h2>
    <div class="sidebar-wrapper" ref="sideBar">
      <SideBar></SideBar>
    </div>
  </div>
</template>

<script>
import { setOptions } from "../../utils";
import { mapState } from "vuex";

export default {
  props: {
    navScale: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeCategory: this.$route.params.category || "educational",
    };
  },
  components: {
    SideBar: () => import("../../components/common/SideBar.vue"),
  },
  computed: {
    ...mapState(["baseUrl", "assetsUrl"]),
    category() {
      return this.$route.params.category || "";
    },
    categoryName() {
      return setOptions("category_list").find((v) => v.value == this.activeCategory)
        .label;
    },
    categoryList() {
      return setOptions("category_list");
    },
  },
  methods: {
    goCategory(item) {
      this.activeCategory = item.value;
      this.$router.push("/redirect/community/" + item.value);
    },
    goPost() {
      this.$router.push("/resources/post");
    },
  },
  created() {
    console.log(this.$route.params);
  },
  watch: {
    category(newV) {
      this.activeCategory = newV;
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-list {
  padding: 20px;
  max-width: 1575px;
  margin: 0 auto;
  transition: 0.3s;

  &.scale {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 200;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #1d1f22;
      opacity: 0.98;
      z-index: -1;
    }

    .nav-list-wrapper {
      display: none;

      .nav-list-item {
        display: inline-block;
        min-width: unset;
        max-width: unset;
        width: inherit;
        margin-right: 40px;
        font-size: 14px;

        .item-label {
          color: #c8c7cc;
          display: inline-block;
        }

        .item-icon {
          display: none;
        }

        &.active,
        &:hover {
          box-shadow: none;

          .item-label {
            color: #5fc7bc;
            text-decoration: underline;
            text-underline-offset: 5px;
          }
        }

        &.active {
          font-size: 16px;
          box-shadow: none;
        }
      }
    }

    .category {
      display: none;
    }

    .sidebar-wrapper {
      padding-top: 0;
    }
  }

  .category {
    span {
      vertical-align: middle;
    }

    .category-title {
      color: #fff;
      margin-right: 20px;
      padding-right: 20px;
      position: relative;
      line-height: 32px;
      height: 32px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translateY(-50%);
        width: 2px;
        height: 24px;
        background-color: #ed6843;
      }
    }

    .category-post {
      color: #c8c7cc;
      font-size: 20px;
      font-weight: normal;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }

  .nav-list-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 4px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: #999;
    }
  }

  .nav-list-item {
    position: relative;
    width: calc(20% - 16px);
    max-width: 295px;
    min-width: 147px;
    margin-right: 20px;
    cursor: pointer;
    margin-bottom: 20px;

    .item-label {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
      color: #fff;
      font-size: 18px;

      span.item-label__cn {
        font-weight: bold;
        display: block;
      }

      span.item-label__en {
        display: block;
        font-size: 14px;
        opacity: 0.8;
      }
    }

    .item-icon {
      width: 100%;
      display: block;
      max-height: 150px;
    }

    &:nth-last-of-type(1) {
      margin-right: 0;
    }

    &.active,
    &:hover {
      box-shadow: 2px 2px 0px #ed6843;
    }
  }

  .sidebar-wrapper {
    padding-top: 20px;
  }
}
</style>